import React, { FC, useMemo } from 'react';
import { UmbracoArticleCard } from '@shared/types';

import ArticleCard from 'components/cards/ArticleCard';
import Button from 'components/elements/Button';
import Carousel from 'components/elements/Carousel';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';
import { sortByOrderUrls } from 'utils/sorting';

import { IPropsPopularArticles } from './models';

import './PopularArticles.scss';
import './ShadowPopularArticles.scss';

const PopularArticles: FC<IPropsPopularArticles> = ({
  data: [data],
  globalData,
  popularArticlesByLink,
  popularArticlesByTag,
  withoutContainerRightPartDesktop = true,
  withoutContainerRightPartMobile = true,
  classNameBaseSection,
}) => {
  const sortArticlesByIndex = useMemo(
    () => sortByOrderUrls(popularArticlesByLink, data.selectedArticlesLinks),
    [data, popularArticlesByLink]
  );

  const itemsToRender =
    data?.selectedArticlesMode === 'manual' && popularArticlesByLink?.length
      ? sortArticlesByIndex
      : popularArticlesByTag;

  return (
    <BaseSection
      data={data.sectionSettings}
      withoutContainerRightPartDesktop={withoutContainerRightPartDesktop}
      withoutContainerRightPartMobile={withoutContainerRightPartMobile}
      className={classNameBaseSection}
    >
      <div data-testid="PopularArticles" className="popular-articles">
        {data?.title?.length ? (
          <Typography data={data.title} customClass="popular-articles__title" />
        ) : null}
        {itemsToRender?.length === 1 ? (
          <ArticleCard
            key={itemsToRender[0].url}
            data={itemsToRender[0].previewData[0]}
            url={itemsToRender[0].url}
            cta={data.ctaCard}
            cardSettings={data.articleCardsSettings}
            customClass="article-card--single"
          />
        ) : (
          <Carousel carouselData={data.carouselSettings} globalData={globalData}>
            {itemsToRender?.map((article: UmbracoArticleCard.IStructure) => (
              <ArticleCard
                key={article.url}
                data={article.previewData[0]}
                url={article.url}
                cta={data.ctaCard}
                cardSettings={data.articleCardsSettings}
              />
            ))}
          </Carousel>
        )}
        {data?.ctaButton?.length ? (
          <div className="popular-articles__cta">
            <Button data={data.ctaButton} />
          </div>
        ) : null}
      </div>
    </BaseSection>
  );
};
export default PopularArticles;
